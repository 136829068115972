.main {
    min-height: 88.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.audit {
    width: 191px;
    height: 191px;
    background: url('/audit.png') no-repeat center center;
    background-size: contain;
    margin: 0 auto 30px;
}

.content {
    font-size: 14px;
    color: #878C9C;
    text-align: center;
    margin-bottom: 100px;

    h2 {
        font-size: 18px;
        color: #111E36;
        margin-bottom: 10px;
    }

    a {
        color: #FF8C00;
        text-decoration: underline;
    }

    .btn {
        background-image: linear-gradient(270deg, #FF8C00 3%, #FF7401 93%);
        border-radius: 40px;
        width: 310px;
        height: 40px;
        line-height: 40px;
        display: block;
        margin: 50px auto 0;
        color: #fff;
        text-decoration: none;
    }
}



@media(max-width:1080px) {
    .main {
        min-height: 86vh;
        flex-grow: 1;
    }

    .audit {
        width: 50.933vw;
        height: 50.933vw;
        margin: 0 auto 8vw;
    }

    .content {
        font-size: 3.733vw;
        color: #878C9C;
        text-align: center;
        padding: 8.667vw;
        margin-bottom: 0;

        h2 {
            font-size: 4.8vw;
            color: #111E36;
            margin-bottom: 2.667vw;
        }



    }
}